<template>
  <div class="col-12 p-0 mb-3" >
    <div class="row mx-0">
      <div class="col-12 text-center" >
        <!-- <Button color="gold" size="xsmall" btnText="Already a donor? Login now" icon="arrow" @buttonClicked="isLogin = true">
          <IconArrowForward size="size16" />
        </Button> -->
        <Button color="gold" btnText="Login now" icon="arrow" @buttonClicked="isLogin = true">
          <IconArrowForward size="size16" />
        </Button>
      </div>
    </div>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
  </div>
  <!-- END PAGE 1 -->
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue'))
  },
  name: 'Daily Sadaqah Login Section',
  data () {
    return {
      isLogin: false
    }
  }
}

</script>
